package earth.worldwind.tutorials

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.ImageResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.ResourcePlatformDetails
import kotlin.String
import kotlin.collections.List

public actual object MR {
  private val contentHash: String = "cb0ef36390a19337de97e6bbf256754a"

  public actual object images : ResourceContainer<ImageResource> {
    public actual override val __platformDetails: ResourcePlatformDetails =
        ResourcePlatformDetails()

    public actual val aircraft_fixwing: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/aircraft_fixwing.png\")") as String, fileName =
        "aircraft_fixwing.png")

    public actual val worldwind_logo: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/worldwind_logo.png\")") as String, fileName = "worldwind_logo.png")

    public actual val aircraft_fighter: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/aircraft_fighter.png\")") as String, fileName =
        "aircraft_fighter.png")

    public actual val ehipcc: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/ehipcc.png\")") as String, fileName = "ehipcc.png")

    public actual val pattern_sample_houndstooth: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/pattern_sample_houndstooth.png\")") as String, fileName =
        "pattern_sample_houndstooth.png")

    public actual val airport_terminal: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/airport_terminal.png\")") as String, fileName =
        "airport_terminal.png")

    public actual override fun values(): List<ImageResource> = listOf(aircraft_fixwing,
        worldwind_logo, aircraft_fighter, ehipcc, pattern_sample_houndstooth, airport_terminal)
  }
}
